import React, { useEffect, useState } from "react";
import { getBuyTokenOrderList } from "../../core/services/api/nft";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "../../core/utils/pagination";
import Modal from "react-bootstrap/Modal";
import AssetRequestDetail from "./../shared/asset-request-detail-popup";
import paginationSubmit from "./../../hooks/pagination";
import { extendTime, formatDateTime } from "../../core/utils/time";
import { Link } from "react-router-dom";
import "../../assets/css/redemption.css";
import useAlert from "./../../hooks/use-alert";
import CopyIcon from "../../assets/images/copy-icon.png";
import { copyText } from "../../core/utils/copy";
import moment from "moment";
const BuyTokenOrderTableData = () => {
  const { selectedCrypto, assetRequest, offSet } = useSelector(
    (state) => state.nftReducer
  );
  const { startDate, endDate, currentPage, pageNumber } = useSelector(
    (state) => state.paginationReducer
  );
  const baseURL = process.env.REACT_APP_API_NATURE_BASE_URL;
  const dispatch = useDispatch();
  const { showAlert } = useAlert();
  const [data, setData] = useState();
  const [assetRequestId, setAssetRequestId] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const getData = async () => {
    const data = {
      page_no: pageNumber,
      type: 'dcm',
    };
    const res = await getBuyTokenOrderList(data);
    if (res.status) {
      setData(res.order_list);
      setTotalCount(res.total_record);
    }
  };
  console.log(data,'-----------39--------------')
  const showModal = (e) => {
    setAssetRequestId(e);
    setIsOpen(true);
  };
  const dateOffSet = (val) => {
    if (offSet !== undefined) {
      const extendedTime = extendTime(val, offSet);
      const formatedDate = formatDateTime(extendedTime);
      return formatedDate;
    }
  };
  const hideModal = () => {
    setIsOpen(false);
  };
  const shortValue = (value) => {
    if (value) {
      const firstFour = value.substr(0, 4);
      const lastFour = value.substr(value.length - 4, value.length);
      return firstFour + "......" + lastFour;
    } else {
      return "N/A";
    }
    /*if(value.length > 15){
      return value.substring(0, 15) + "...";
    }else{
      return value;
    }*/
  };
  useEffect(() => {
    if (assetRequest === null) {
      getData();
    } else {
      setData(assetRequest.asset_request_list);
      setTotalCount(assetRequest.total_records);
    }
  }, [selectedCrypto, assetRequest, currentPage]);

  return (
    <>
      {data && offSet !== undefined && (
        <Pagination
          callback={(e) =>
            // eslint-disable-next-line implicit-arrow-linebreak
            assetRequest === null
              ? getData()
              : paginationSubmit(
                  startDate,
                  endDate,
                  currentPage,
                  pageNumber,
                  selectedCrypto,
                  dispatch
                )
          }
          totalCount={totalCount}
          totalShow={data.length}>
          <div className="table-responsive">
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>Order#</th>
                  <th>Qty</th>
                  <th>Amount</th>
                  <th>Type</th>
                  <th>Transaction Id</th>
                  <th>Wallet Address</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Blockchain Txn</th>
                </tr>
              </thead>
              <tbody>
                {data && data.length > 0 ? (
                  <>
                    {data.map((val, index) => (
                      <tr key={index}>
                        <td>{val.order_name}</td>
                        <td>{val.hundred_club_ticket_quant ? val.hundred_club_ticket_quant : val.qty } </td>
                        <td title={val.amount}>
                          {val.amount &&
                            val.amount.toLocaleString(undefined, {
                              minimumFractionDigits: 5,
                            })}{" "}
                          (<img src={baseURL + val.currency_image} alt="" />
                          {val.currency_name})
                        </td>
                        <td>{val.type}</td>
                        <td title={val.transaction_reference}>
                          {shortValue(val.transaction_reference)}{" "}
                          <img
                            className="cursor-pointer"
                            src={CopyIcon}
                            onClick={() => {
                              copyText(val.transaction_reference),
                                showAlert(
                                  "SUCCESS",
                                  "Success",
                                  "Transaction Reference Copied"
                                );
                            }}
                          />
                        </td>
                        <td title={val.user_wallet_address}>
                          {shortValue(val.user_wallet_address)}{" "}
                          <img
                            className="cursor-pointer"
                            src={CopyIcon}
                            onClick={() => {
                              copyText(val.user_wallet_address),
                                showAlert(
                                  "SUCCESS",
                                  "Success",
                                  "Wallet Address Copied"
                                );
                            }}
                          />
                        </td>
                        <td>{moment(val.date_order).format("D-MMM-yy")}</td>
                        <td>
                          {val.state.toLowerCase() == "transfer_token" ? (
                            <Link
                              onClick={(e) => e.preventDefault()}
                              className={`btan done`}>
                              Transfer Token
                            </Link>
                          ) : val.state.toLowerCase() ==
                            "transfer_token_failed" ? (
                            <Link
                              onClick={(e) => e.preventDefault()}
                              className={`btan reject`}>
                              Transfer Failed
                            </Link>
                          ) : val.state.toLowerCase() ==
                          "hct_draft" ? (
                            <Link
                            onClick={(e) => e.preventDefault()}
                            className={`btan pending`}>
                            THCNFT Open
                            </Link>
                          ) : (
                            <Link
                              onClick={(e) => e.preventDefault()}
                              className={`btan ${val.state.toLowerCase()}`}>
                              {val.state}
                            </Link>
                          )}
                        </td>
                        <td>
                          {val.state != "Pending" ? (
                            <>
                              {val.blockchain_url !== "" && (
                                <a
                                  onClick={() => {
                                    window.open(val.blockchain_url);
                                  }}
                                  target="_blank"
                                  className="btan done pointer">
                                  View
                                </a>
                              )}
                            </>
                          ) : (
                            <a target="_blank" className="btan pending ">
                              Pending
                            </a>
                          )}
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan="8">No records found.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Pagination>
      )}
    </>
  );
};

export default BuyTokenOrderTableData;
